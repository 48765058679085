<template>
  <div :class="['input', { 'icon-search': search, error: error, password: password }]">
    <div
      v-if="label"
      class="input__label text-[#292929]"
      :class="labelColor ? `text-[${labelColor}]` : ''"
    >
      {{ label }}:
    </div>

    <div class="input__inner">
      <input
        :placeholder="placeholder || $t('fill_field')"
        :type="myType"
        :value="modelValue"
        :disabled="disabled"
        :autocomplete="autocomplete"
        @input="change"
        v-mask="mask"
        @keyup.enter="emit('keyup.enter')"
        @blur="emit('blur')"
        @focus="emit('focus')"
      />
      <div
        v-if="search"
        class="input__icon-search"
      >
        <UiIcon
          width="17px"
          height="17px"
          :color="iconColor"
          value="search-input"
        />
      </div>

      <div
        v-if="templateEditingInfo"
        class="input__icon-info">
        <UiTooltip
          :text="templateEditingInfoText"
          background="#DBEBFF"
          color="#20579A"
          left
        >
          <template #activator>
            <UiIcon
              value="information"
              width="18px"
              height="18px"
              color="#BEC7D3"
            />
          </template>
        </UiTooltip>
      </div>

      <transition
        appear
        name="scale"
      >
        <div
          v-if="password"
          class="input__eye"
        >
          <UiIcon
            :color="modelValue ? '#292929' : '#c6c7cb'"
            width="26px"
            height="22px"
            :value="eyeIcon"
            @click="changeType"
          />
        </div>
      </transition>

      <transition
        appear
        name="slide-fade"
      >
        <div
          v-if="error && errorText"
          class="input__error !mt-0 pt-0.5"
        >
          {{ errorText }}
        </div>

      </transition>
      <div
        v-if="!error && !errorText && text"
        class="input__text"
      >
        {{ text }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineProps, computed, ref, defineEmits, onMounted } from 'vue';
import UiTooltip from '@/components/ui/tooltip.vue';
import UiIcon from './icon';

const props = defineProps({
  modelValue: [String, Number],

  placeholder: {
    type: String,
    default: null
  },

  disabled: {
    type: Boolean,
    default: false
  },

  autocomplete: {
    type: String
  },

  password: {
    type: Boolean,
    default: false
  },

  mask: {
    type: String,
    default: ''
  },

  search: {
    type: Boolean,
    default: false
  },

  templateEditingInfo: {
    type: Boolean,
    default: false
  },

  templateEditingInfoText: {
    type: String,
    default: ''
  },

  error: {
    type: Boolean,
    default: false
  },

  errorText: {
    type: String,
    default: ''
  },

  text: {
    type: String,
    default: ''
  },

  label: {
    type: String,
    default: ''
  },

  labelColor: {
    type: String,
    default: '',
  },

  type: {
    type: String,
    default: 'text'
  },

  width: {
    type: String,
    default: '100%'
  },

  height: {
    type: String,
    default: 'max-content'
  },

  background: {
    type: String,
    default: '#EDEFF3'
  },

  borderRadius: {
    type: String,
    default: '12px'
  },

  iconColor: {
    type: String,
    default: '#292929'
  }
});
const emit = defineEmits(['update:modelValue', 'change', 'keyup.enter', 'blur', 'focus']);

const myType = ref(props.type);
const showPassword = ref(false);

if (props.password) {
  myType.value = 'password';
}

const changeType = () => {
  if (props.modelValue) {
    showPassword.value = !showPassword.value;
    myType.value = showPassword.value ? 'text' : 'password';
  }
};

const backgroundInput = computed(() => {
  return props.background;
});

const eyeIcon = computed(() => {
  return !props.modelValue ? 'eye' : showPassword.value ? 'eye-off' : 'eye';
});

const change = (e: Event) => {
  emit('update:modelValue', (e.target as HTMLInputElement).value);
  emit('change', (e.target as HTMLInputElement).value);
};
onMounted(() => {
  // const some_id = document.getElementById('input');
  // some_id.type = 'text';
  // some_id.removeAttribute('autocomplete');
});
</script>

<style lang="scss" scoped>
.input {
  position: relative;
  display: flex;
  padding: 3px 12px 8px;
  width: v-bind(width);
  height: v-bind(height);
  font-size: 16px;
  background: v-bind(backgroundInput);
  border-radius: v-bind(borderRadius);
  flex-direction: column;
  box-sizing: border-box;
  line-height: 24px;

  &__label {
    font-size: 13px;
    white-space: nowrap;
    font-weight: 700;
    line-height: 19px;
  }

  &__inner {
    position: relative;
    height: 19px;

    input {
      width: 100%;
      font-size: 16px;
      color: #292929;
      background: v-bind(backgroundInput);
      border: none;
      outline: none;
      font-weight: 400;
      line-height: 19px;
      mix-blend-mode: luminosity;

      &::placeholder {
        font-size: 16px;
        color: #828d9b;
      }
    }
  }

  &__icon {
    &-remove {
      position: absolute;
      top: 50%;
      right: 0;
      transition: 0.3s;
      cursor: pointer;
      transform: translateY(-50%);
    }

    &-search {
      position: absolute;
      top: 50%;
      left: 0;
      transition: 0.3s;
      cursor: pointer;
      transform: translateY(-50%);
    }

    &-info {
      position: absolute;
      right: 0;
      bottom: 5px;
    }
  }

  &__eye {
    position: absolute;
    top: 2px;
    right: 0;
    transition: 0.3s;
    cursor: pointer;
    transform: translateY(-50%);
  }

  &__error {
    margin-top: 3px;
    font-weight: 400;
    font-size: 12px;
    line-height: 140%;
    color: #c93131;
  }

  &__text {
    margin-top: 5px;
    font-weight: 400;
    font-size: 12px;
    line-height: 140%;
    color: #bec7d3;
  }

  &.icon-search {
    padding: 0 12px !important;

    .input__inner {
      display: flex;
      height: 100%;

      & input {
        padding-left: 26px;
        font-size: 14px;
        color: #828d9b;
        font-weight: 600;
        line-height: 18px;

        &::placeholder {
          font-size: 14px;
          color: #828d9b !important;
        }
      }
    }

    &.error {
      input {
        color: #c93131;
        mix-blend-mode: normal;

        &::placeholder {
          font-weight: 400;
          color: #c93131;
        }
      }
    }
  }

  &.error {
    .input {
      &__label {
        color: #c93131;
      }
    }

    input {
      color: #c93131;
      mix-blend-mode: normal;

      &::placeholder {
        font-weight: 400;
        color: #c93131;
      }
    }
  }

  &.password {
    input {
      padding-right: 30px;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}

:deep(.tooltip__body) {
  top: 72px !important;
  right: 15px !important;
  width: 300px;
}
</style>
