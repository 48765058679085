import { defineStore } from 'pinia';
import { Company } from '@/api/company.js';

export const companyStore = defineStore('storeCompany', {
  state: () => ({
    selectedCompany: null,
    allCompanies: [],
    allCompaniesFiltered: [],
    companiesMax: 0,
    count: 100,
    search: '',
    geozonsResult: 0,
    errorMessage: '',
    filter: {
      integrator: null,
      status: null,
      timezone: null,
      companyFilterModel: ''
    },
    conditions: [],
    sortField: '',
    sortDesc: ''
  }),

  actions: {
    async getCompany(id) {
      try {
        const response = await Company.getCompanyById(id);
        this.selectedCompany = response;
        return response;
      } catch (e) {
        throw new Error(e);
      }
    },
    async postAllCompanies(data, pagination) {
      data.value = this.search;

      if (this.sortDesc !== '') {
        data.sortDesc = this.sortDesc;
        data.sortField = this.sortField;
      }

      if (this.filter.integrator) {
        data.dealer = { id: this.filter.integrator };
      }

      if (this.filter.timezone) {
        data.conditions = [
          {
            field: "timezone_key",
            value: this.filter.timezone,
          }
        ]
      }

      if (this.filter.status) {
        data.conditions = [
          {
            field: "billing.company_status",
            value: this.filter.status,
          }
        ]
      }

      try {
        if (!this.allCompanies.length || pagination || data.dealer) {
          const response = await Company.postAllCompanies(data);

          this.allCompanies = response.list;
          this.allCompaniesFiltered = response.list;
          this.companiesMax = response.max;

          return response;
        }
      } catch (e) {
        throw new Error(e);
      }
    },
    async deleteCompanies(id) {
      try {
        const response = await Company.deleteCompanies(id);
        this.allCompaniesFiltered = this.allCompaniesFiltered.filter((val) => val.id !== id[0]);
        this.companiesMax -= 1;
        return response;
      } catch (e) {
        throw new Error(e);
      }
    },
    async createCompanies(data) {
      try {
        return await Company.createCompanies(data);
      } catch (e) {
        console.log(e);
      }
    },
    async updateCompanies(data) {
      try {
        return await Company.updateCompanies(data);
      } catch (e) {
        throw new Error(e);
      }
    },
    async filterCompanies(data) {
      try {
        const value = data.value;
        const conditions = data.conditions || [];
        const field = data.field;

        return await this.postAllCompanies({ from: 0, count: this.count, value, conditions, field }, true);
      } catch (e) {
        throw new Error(e);
      }
    },
    async postFilesToCompanies(file, companies_ids) {
      try {
        const response = await Company.postFilesToCompanies(file, companies_ids);
        if (response.result) {
          const count = Number(response.result.split(':')[1]);
          this.geozonsResult += count;
        } else {
          this.errorMessage = response.error.message;
        }
        return response;
      } catch (e) {
        throw new Error(e);
      }
    },
    async clearCompanyFilters() {
      try {
        this.filter.companyFilterModel = '';
        this.filter.status = null;
        this.filter.integrator = null;
        this.filter.timezone = null;

        await this.filterCompanies({ from: 0, count: this.count, });
      } catch (error) {
        console.error('Error during clearing companies:', error);
      }
    },
    async WEBSOCKET_CHANGE({ id, operation }) {
      if (operation === 'DELETE') return this.DELETE_UNIT(id);

      const { list } = await Company.postAllCompanies({
        from: 0,
        count: 1,
        value: id
      });

      operation === 'POST' ? this.ADD_COMPANY(list[0]) : this.UPDATE_COMPANY(list[0]);
    },

    ADD_COMPANY(company) {
      if (!this.allCompaniesFiltered.some((x) => x.id === company.id)) {
        this.allCompanies = this.allCompaniesFiltered = [...this.allCompaniesFiltered, company];
      }
    },

    UPDATE_COMPANY(company) {
      const idx = this.allCompaniesFiltered.findIndex((elem) => elem.id === company.id);

      if (idx === -1) return;

      console.log(company, 'company UPDATE 2');
      this.allCompanies = this.allCompaniesFiltered = [...this.allCompaniesFiltered.slice(0, idx), company, ...this.allCompaniesFiltered.slice(idx + 1)];
    },

    DELETE_UNIT(id) {
      this.allCompanies = this.allCompaniesFiltered = this.allCompaniesFiltered.filter((item) => item.id !== id);
    },
    SET_ERROR() {
      this.errorMessage = '';
    },
    updateSortedCompanies(payload) {
      this.allCompaniesFiltered = payload.list;
    }
  },

  getters: {
    getSelectedCompany: (state) => state.selectedCompany,
    getAllCompanies: (state) => state.allCompaniesFiltered,
    getGeozonsResult: (state) => state.geozonsResult,
    getCompanyById: (state) => (id) => {
      return state.allCompaniesFiltered.find((company) => company.id === id);
    },
    getCompaniesList: (state) => {
      return state.allCompanies;
    },
    getErrorMessage: (state) => state.errorMessage
  }
});
